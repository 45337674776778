<template>
  <div class="modal-container modal-container-change-password" :class="{ hidden: !display }" tabindex="-1" role="dialog"
    :aria-hidden="!display" @keydown="escapeToClose">
    <form @submit="submit" class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{ $t("Replace wallet") }}</div>
          <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>{{ $t("Warning: If you replace your wallet, your old wallet will be deleted. Make sure to backup its private key before doing this.") }}</label>
          </div>
          <div class="form-group">
            <label>{{ $t("Wallet password") }}:</label>
            <PasswordInput :disabled="busy" v-model:val="createWalletPassword" name="password"></PasswordInput>
          </div>
          <div class="form-group">
            <label>{{ $t("Repeat wallet password") }}:</label>
            <PasswordInput :disabled="busy" v-model:val="createWalletPassword2" name="password2"></PasswordInput>
          </div>
          <div class="form-group">
            <label>{{ $t("Private key (Hexadecimal. If you leave it blank, it will generate a random one)") }}:</label>
            <textarea class="form-control form-control-full-width global-border" rows="3"
              v-model="createWalletPrivateKey"></textarea>
          </div>
          <div class="d-flex flex-row justify-content-start align-items-center">
            <div class="col-5"><label>{{ $t("Confirm this action?") }}: </label></div>
            <div class="col-7"><ToggleSwitch v-model:val="confirmation"></ToggleSwitch></div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" :disabled="busy || !confirmation" class="btn btn-danger">
            <i class="fa-solid fa-repeat"></i>{{ $t("Replace wallet") }}
          </button>
          <div class="form-error">{{ error }}</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Request } from "@asanrom/request-browser";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/v-model";
import { FocusTrap } from "@/utils/focus-trap";
import { ApiWallet } from "@/api/api-group-wallet";
import { AuthController } from "@/control/auth";
import PasswordInput from "@/components/utils/PasswordInput.vue";
import ToggleSwitch from "@/components/utils/ToggleSwitch.vue";

export default defineComponent({
  components: {
    PasswordInput,
    ToggleSwitch,
  },
  name: "WalletReplaceModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      focusTrap: null as FocusTrap,
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      userRole: AuthController.SignupSelectedRole,

      createWalletPassword: "",
      createWalletPassword2: "",
      createWalletPrivateKey: "",

      confirmation: false,

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.createWalletPassword = "";
      this.createWalletPassword2 = "";
      this.createWalletPrivateKey = "";
      this.error = "";
      this.confirmation = false;
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      if (!this.createWalletPassword) {
        this.error = this.$t("You must specify a password");
        return;
      }

      if (this.createWalletPassword !== this.createWalletPassword2) {
        this.error = this.$t("The passwords do not match");
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(ApiWallet.CreateWallet({ password: this.createWalletPassword, private_key: this.createWalletPrivateKey, wallet_kind: this.userRole }))
        .onSuccess(() => {
          this.busy = false;
          this.close();
          AuthController.CheckAuthStatus();
          this.$showSnackBar(this.$t("Wallet replaced successfully"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err, handleErr) => {
          this.busy = false;
          handleErr(err, {
            unauthorized: () => {
              this.$requireLogin();
            },
            badRequestAlreadySubmitted: () => {
              this.error = this.$t("You already submitted a request");
            },
            badRequestWalletInUse: () => {
              this.error = this.$t("Wallet in use");
            },
            badRequestInvalidPrivateKey: () => {
              this.error = this.$t("Invalid private key provided for the wallet");
            },
            badRequestWeakPassword: () => {
              this.error = this.$t("The provided password is too short");
            },
            badRequest: () => {
              this.error = this.$t("Bad request");
            },
            serverError: () => {
              this.error = this.$t("Internal server error");
            },
            networkError: () => {
              this.error = this.$t("Could not connect to the server");
            },
          });
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.focusTrap = new FocusTrap(this.$el, this.close.bind(this));

    if (this.display) {
      this.focusTrap.activate();
      nextTick(() => {
        this.$el.focus();
      });
    }
  },
  beforeUnmount: function () {
    this.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.focusTrap.activate();
        this.onOpen();
      } else {
        this.focusTrap.deactivate();
      }
    },
  },
});
</script>

<style></style>
