<template>
  <div
    class="modal-container modal-container-change-password"
    :class="{ hidden: !display }"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!display"
    @keydown="escapeToClose"
  >
    <form
      @submit="submit"
      class="modal-dialog modal-md"
      role="document"
      @click="stopPropagationEvent"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{ $t("Change wallet password") }}</div>
          <button
            type="button"
            class="modal-close-btn"
            :title="$t('Close')"
            @click="close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>{{ $t("Current password") }}:</label>
            <PasswordInput :disabled="busy" v-model:val="oldPassword" name="old_password"></PasswordInput>
          </div>
          <div class="form-group">
            <label>{{ $t("New password") }}:</label>
            <PasswordInput :disabled="busy" v-model:val="password" name="password"></PasswordInput>
          </div>
          <div class="form-group">
            <label>{{ $t("Repeat the new password") }}:</label>
            <PasswordInput :disabled="busy" v-model:val="password2" name="password2"></PasswordInput>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-group">
            <button
              type="submit"
              :disabled="busy"
              class="btn btn-primary"
            >
            <i class="fas fa-key"></i>{{ $t("Change password") }}
            </button>
          </div>
          <div class="form-error">{{ error }}</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Request } from "@asanrom/request-browser";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/v-model";
import { FocusTrap } from "@/utils/focus-trap";
import { ApiWallet } from "@/api/api-group-wallet";
import PasswordInput from "@/components/utils/PasswordInput.vue";

export default defineComponent({
  components: {
    PasswordInput,
  },
  name: "WalletChangePasswordModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
        return {
            focusTrap: null as FocusTrap,
            displayStatus: useVModel(props, "display"),
        };
  },
  data: function () {
    return {
      oldPassword: "",

      password: "",
      password2: "",

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.oldPassword = "";
      this.password = "";
      this.password2 = "";
      this.error = "";
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      if (!this.password) {
        this.error = this.$t("You must specify a password");
        return;
      }

      if (this.password !== this.password2) {
        this.error = this.$t("The passwords do not match");
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(ApiWallet.ChangeWalletPassword({ password: this.oldPassword, new_password: this.password }))
        .onSuccess(() => {
          this.busy = false;
          this.close();
          this.$showSnackBar(this.$t("Wallet password successfully changed"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err, handleErr) => {
          this.busy = false;
          handleErr(err, {
            unauthorized: () => {
              this.$requireLogin();
            },
            badRequestWrongPassword: () => {
              this.error = this.$t("Wrong password");
            },
            badRequestWeakPassword: () => {
              this.error = this.$t("The provided password is too short");
            },
            badRequest: () => {
              this.error = this.$t("Bad request");
            },
            notFound: () => {
              this.error = this.$t("Not found");
            },
            serverError: () => {
              this.error = this.$t("Internal server error");
            },
            networkError: () => {
              this.error = this.$t("Could not connect to the server");
            },
          });
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.focusTrap = new FocusTrap(this.$el, this.close.bind(this));

    if (this.display) {
      this.focusTrap.activate();
      nextTick(() => {
        this.$el.focus();
      });
    }
  },
  beforeUnmount: function () {
    this.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.focusTrap.activate();
        this.onOpen();
      } else {
        this.focusTrap.deactivate();
      }
    },
  },
});
</script>

<style>
</style>
