// API bindings: wallet (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl } from "./utils";
import { WalletInfo, WalletCreateBody, WalletChangePasswordBody, WalletExportResponse, WalletExportBody } from "./definitions";

export class ApiWallet {
    /**
     * Method: GET
     * Path: /wallet
     * Get wallet
     * @returns The request parameters
     */
    public static GetWallet(): RequestParams<WalletInfo, GetWalletErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/wallet`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /wallet
     * Create wallet
     * @param body Body parameters
     * @returns The request parameters
     */
    public static CreateWallet(body: WalletCreateBody): RequestParams<WalletInfo, CreateWalletErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/wallet`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "WALLET_IN_USE", handler.badRequestWalletInUse)
                    .add(400, "ALREADY_SUBMITTED", handler.badRequestAlreadySubmitted)
                    .add(400, "INVALID_PRIVATE_KEY", handler.badRequestInvalidPrivateKey)
                    .add(400, "WEAK_PASSWORD", handler.badRequestWeakPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /wallet/password
     * Change wallet password
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ChangeWalletPassword(body: WalletChangePasswordBody): RequestParams<void, ChangeWalletPasswordErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/wallet/password`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "WEAK_PASSWORD", handler.badRequestWeakPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /wallet/export
     * Export wallet private key
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ExportPrivatekey(body: WalletExportBody): RequestParams<WalletExportResponse, ExportPrivatekeyErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/wallet/export`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for GetWallet
 */
export type GetWalletErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for CreateWallet
 */
export type CreateWalletErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Password too weak
     */
    badRequestWeakPassword: () => void;

    /**
     * Invalid private key provided
     */
    badRequestInvalidPrivateKey: () => void;

    /**
     * Wallet verification request already submitted
     */
    badRequestAlreadySubmitted: () => void;

    /**
     * Another user has the same wallet
     */
    badRequestWalletInUse: () => void;
};

/**
 * Error handler for ChangeWalletPassword
 */
export type ChangeWalletPasswordErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Password too weak
     */
    badRequestWeakPassword: () => void;

    /**
     * Wrong current password
     */
    badRequestWrongPassword: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for ExportPrivatekey
 */
export type ExportPrivatekeyErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Wrong current password
     */
    badRequestWrongPassword: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

