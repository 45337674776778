<template>
    <div class="top-bar" tabindex="-1">
        <div @click="goHome" class="top-bar-logo-td">
            <img class="top-bar-logo" src="/svg/logo-GSD-sp-green.svg" />
        </div>

        <nav class="top-bar-menu-group">
            <ul class="top-bar-menu-list">
                <li class="menu-item-group no-border">
                    <router-link class="menu-item" :class="{ 'router-link-active': page === 'home' }"
                        :title="$t('Home')" :to="{ name: 'home' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Home") }}</p>
                        </div>
                    </router-link>
                </li>

                <li class="menu-item-group no-border">
                    <router-link class="menu-item" :class="{ 'router-link-active': page === 'patents' || page === 'patent-single' }"
                        :title="$t('Patents')" :to="{ name: 'patents' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Patents") }}</p>
                        </div>
                    </router-link>
                </li>

                <li class="menu-item-group no-border">
                    <router-link class="menu-item" :class="{ 'router-link-active': page === 'projects' || page === 'project-single' }"
                        :title="$t('Projects')" :to="{ name: 'projects' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Projects") }}</p>
                        </div>
                    </router-link>
                </li>

                <li v-if="loggedIn" class="menu-item-group no-border">
                    <router-link class="menu-item" :class="{ 'router-link-active': page === 'dashboard' || page === 'dashboard-patents' 
                    || page === 'dashboard-projects' || page === 'dashboard-patent-single' || page === 'dashboard-project-single'
                    || page === 'dashboard-companies'}"
                        :title="$t('Dashboard')" :to="{ name: 'dashboard-patents' }">
                        <div class="menu-container">
                            <p class="menu-item-text">{{ $t("Dashboard") }}</p>
                        </div>
                    </router-link>
                </li>

            </ul>
        </nav>

        <div class="top-bar-user-td" v-if="loggedIn">
            <div class="buttons-container">
                <button type="button" class="top-bar-button" :title="$t('Select your language')"
                    @click="selectLanguage">
                    <i class="fa-solid fa-earth-europe icon-color"></i>
                </button>
                <button type="button" class="top-bar-button notifications" :title="$t('Notifications')" @click="openNotifications">
                    <div v-if="hasNotifications" class="has-notifications"></div>
                    <i class="fas fa-bell icon-color"></i>
                </button>
                <button type="button" class="top-bar-button-img" :title="$t('User settings')" @click="openUserSettings">
                    <img v-if="profileImage" class="btn-image" :src="profileImage" />
                    <i v-else class="fa-solid fa-user icon-color"></i>
                </button>                
                <button type="button" class="top-bar-button menu" :title="$t('Menu')" @click="openMenu">
                    <i class="fas fa-bars icon-color"></i>
                </button>
            </div>
        </div>

        <div class="top-bar-user-td" v-if="!loggedIn">
            <div class="buttons-container"> 
                <button type="button" class="top-bar-button" :title="$t('Select your language')"
                    @click="selectLanguage">
                    <i class="fa-solid fa-earth-europe icon-color"></i>
                </button>
                <button type="button" @click="login" class="btn btn-primary">
                    {{ $t("Login") }}
                </button>
                <button type="button" class="top-bar-button menu" :title="$t('Menu')" @click="openMenu">
                    <i class="fas fa-bars icon-color"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { getCurrency } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { getUniqueStringId } from "@/utils/unique-id";
import { NotificationsController } from "@/control/notifications";

export default defineComponent({
    components: {},
    name: "TopBar",
    emits: ["openModal"],
    setup: function () {
        return {
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            page: 'home',
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            profileName: AuthController.ProfileName || AuthController.Username || "",

            balance: "",
            currency: getCurrency(),

            hasNotifications: NotificationsController.UnreadCount > 0,
        };
    },
    methods: {
        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : '';
        },

        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        openNotifications: function () {
            this.$emit("openModal", "notifications");
        },

        openMenu: function () {
            this.$emit("openModal", "menu");
        },

        openBalance: function () {
            this.$emit("openModal", "balance-open");
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        signup: function () {
            this.$router.push({ name: "signup" });
        },

        login: function () {
            this.$router.push({ name: "login" });
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
            this.refresh();
        },

        refresh: function () {
            if (this.loggedIn) {
                this.currency = getCurrency();
            }
        },

        onNotificationsChanged: function () {
            this.hasNotifications = NotificationsController.UnreadCount > 0;
        },

        goHome: function () {
            this.$router.push({ name: "home" });
        }
    },
    mounted: function () {
        this.$listenOnAppEvent("balance-changed", this.refresh.bind(this));
        this.$listenOnAppEvent("currency-changed", this.refresh.bind(this));
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("notifications-status-changed", this.onNotificationsChanged.bind(this));
        this.updatePage();
    },
    beforeUnmount: function () { },
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
});
</script>

<style scoped>
</style>