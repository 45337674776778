<template>
  <div class="modal-container modal-container-change-password" :class="{ hidden: !display }" tabindex="-1" role="dialog"
    :aria-hidden="!display" @keydown="escapeToClose">
    <form @submit="submit" class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{ $t("Export wallet key") }}</div>
          <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" v-if="locked">
            <label>{{ $t("Wallet password") }}:</label>
            <PasswordInput :disabled="busy" v-model:val="password" name="wallet_password"></PasswordInput>
          </div>
          <div class="form-group" v-if="!locked">
            <label>{{ $t("Wallet private key") }}:</label>
            <textarea class="form-control form-control-full-width" rows="3" v-model="key" readonly></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-group" v-if="locked">
            <button type="submit" :disabled="busy" class="btn btn-primary">
              <i class="fa-solid fa-file-export"></i>{{ $t("Export key") }}
            </button>
          </div>
          <div class="form-group" v-if="!locked">
            <button type="button" class="btn btn-primary" @click="close">
              {{ $t("Close") }}
            </button>
          </div>
          <div class="form-error">{{ error }}</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Request } from "@asanrom/request-browser";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/v-model";
import { FocusTrap } from "@/utils/focus-trap";
import { ApiWallet } from "@/api/api-group-wallet";
import PasswordInput from "@/components/utils/PasswordInput.vue";

export default defineComponent({
  components: {
    PasswordInput,
  },
  name: "WalletExportKeyModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      focusTrap: null as FocusTrap,
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      password: "",

      key: "",

      locked: true,

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      this.displayStatus = true;
    },

    onOpen: function () {
      this.password = "";
      this.locked = true;
      this.error = "";
      this.key = "";
      this.autoFocus();
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    submit: function (e) {
      if (e) {
        e.preventDefault();
      }
      if (this.busy) {
        return;
      }

      if (!this.password) {
        this.error = this.$t("You must specify a password");
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(ApiWallet.ExportPrivatekey({ password: this.password }))
        .onSuccess((result) => {
          this.key = result.private_key + "";
          this.busy = false;
          this.locked = false;
          this.$showSnackBar(this.$t("Wallet key exported successfully"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err, handleErr) => {
          this.busy = false;
          handleErr(err, {
            unauthorized: () => {
              this.$requireLogin();
            },
            badRequestWrongPassword: () => {
              this.error = this.$t("Wrong password");
            },
            badRequest: () => {
              this.error = this.$t("Bad request");
            },
            notFound: () => {
              this.error = this.$t("Not found");
            },
            serverError: () => {
              this.error = this.$t("Internal server error");
            },
            networkError: () => {
              this.error = this.$t("Could not connect to the server");
            },
          });
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.focusTrap = new FocusTrap(this.$el, this.close.bind(this));

    if (this.display) {
      this.focusTrap.activate();
      nextTick(() => {
        this.$el.focus();
      });
    }
  },
  beforeUnmount: function () {
    this.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.focusTrap.activate();
        this.onOpen();
      } else {
        this.focusTrap.deactivate();
      }
    },
  },
});
</script>

<style></style>
