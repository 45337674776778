// Routes

"use strict";

import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { Timeouts } from "./utils/timeout";
import { AppEvents } from "./control/app-events";

// Router
// https://router.vuejs.org/guide/#javascript

// List of forbidden routes:
//
//    - /api/*  - This is reserved for the API
//    - /static/* - This is reserved for static assets
//    - /webhooks*/ - Reserved for webhooks

const routes: (RouteRecordRaw)[] = [
    /* General / Home */

    {
        name: "home",
        path: "/",
        component: () => import("@/components/routes/HomePage.vue"),
    },

    {
        name: "patents",
        path: "/patents",
        component: () => import("@/components/routes/patents/PatentsPage.vue"),
    },

    {
        name: "patent-single",
        path: "/patents/:id",
        component: () => import("@/components/routes/patents/PatentSinglePage.vue"),
    },

    {
        name: "dashboard-patent-single",
        path: "/dashboard/patents/:id",
        component: () => import("@/components/routes/patents/PatentSinglePage.vue"),
    },

    {
        name: "projects",
        path: "/projects",
        component: () => import("@/components/routes/projects/ProjectsPage.vue"),
    },

    {
        name: "dashboard",
        path: "/dashboard",
        component: () => import("@/components/routes/DashboardPage.vue"),
        children: [
            {
                name: "dashboard-patents",
                path: "patents",
                component: () => import("@/components/routes/DashboardPatentsPage.vue"),
            },
            {
                name: "dashboard-projects",
                path: "projects",
                component: () => import("@/components/routes/DashboardProjectsPage.vue"),
            },
            {
                name: "dashboard-companies",
                path: "companies",
                component: () => import("@/components/routes/DashboardCompaniesPage.vue"),
            }
        ]
    },

    {
        name: "project-single",
        path: "/projects/:id",
        component: () => import("@/components/routes/projects/ProjectSinglePage.vue"),
    },

    {
        name: "dashboard-project-single",
        path: "/dashboard/projects/:id",
        component: () => import("@/components/routes/projects/ProjectSinglePage.vue"),
    },

    {
        name: "rates-info",
        path: "/rates",
        component: () => import("@/components/routes/RatesInfoPage.vue"),
    },

    {
        name: "current-rate",
        path: "/current-rate",
        component: () => import("@/components/routes/CurrentRatePage.vue"),
    },

    {
        name: "about",
        path: "/about",
        component: () => import("@/components/routes/AboutPage.vue"),

    },

    {
        name: "support",
        path: "/support",
        component: () => import("@/components/routes/SupportPage.vue"),
    },

    {
        name: "terms",
        path: "/terms",
        component: () => import("@/components/routes/TermsOfUsePage.vue"),
    },

    {
        name: "cookies",
        path: "/cookies",
        component: () => import("@/components/routes/CookiePolicyPage.vue"),
    },

    {
        name: "privacy",
        path: "/privacy",
        component: () => import("@/components/routes/PrivacyPolicyPage.vue"),
    },

    /* Auth */

    {
        name: "login",
        path: "/login",
        component: () => import("@/components/routes/auth/LoginPage.vue"),
    },

    {
        name: "tfa-login",
        path: "/login/tfa",
        component: () => import("@/components/routes/auth/TwoFactorLoginPage.vue"),
    },

    {
        name: "wallet-create",
        path: '/wallet/create',
        component: () => import("@/components/routes/wallet/WalletCreationPage.vue"),
    },

    {
        name: "wallet-verification",
        path: '/wallet/verification',
        component: () => import("@/components/routes/kyc/WalletVerification.vue")
    },

    {
        name: "signup",
        path: "/signup",
        component: () => import("@/components/routes/auth/SignupPage.vue"),
    },
    
    {
        name: "signup-success",
        path: "/signup/success",
        component: () => import("@/components/routes/auth/SignupSuccessPage.vue"),
    },

    {
        name: "reset-password",
        path: "/password/reset/:uid/:token",
        component: () => import("@/components/routes/auth/ResetPasswordPage.vue"),
    },

    {
        name: "verify-email",
        path: "/email/verify/:uid/:token",
        component: () => import("@/components/routes/auth/EmailVerifyPage.vue"),
    },

    {
        name: "tp-login",
        path: "/login/tp/:service",
        component: () => import("@/components/routes/auth/ThirdPartyLogin.vue"),
    },

    {
        name: "tp-signup",
        path: "/signup/tp",
        component: () => import("@/components/routes/auth/ThirdPartySignupPage.vue"),
    },

    /* Profile */

    {
        name: "profile",
        path: "/user/:username",
        component: () => import("@/components/routes/profile/ProfilePage.vue"),
    },

    /* Account */

    {
        name: "account-settings",
        path: "/account-settings",
        component: () => import("@/components/routes/account/AccountSettingsPage.vue"),
    },

    /* Payment methods */

    {
        name: "payment-methods",
        path: "/payment-methods",
        component: () => import("@/components/routes/PaymentMethodsPage.vue"),
    },

    /* Admin */

    {
        name: "admin",
        path: "/admin",
        component: () => import("@/components/routes/admin/AdministrationPage.vue"),
    },

    {
        name: "admin-user",
        path: "/admin/users/:id",
        component: () => import("@/components/routes/admin/UserPage.vue"),
    },

    /* Default */

    {
        path: "/:catchAll(.*)",
        component: () => import("@/components/routes/NotFoundPage.vue"),
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        history: createWebHistory(),
        routes,
        scrollBehavior() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const mainContainer = document.querySelector(".content-layout");
                    if (mainContainer) {
                        mainContainer.scrollTop = 0;
                    }
                    resolve({ top: 0, left: 0 });
                }, 10);
            });
        }
    });

    router.beforeEach(() => {
        Timeouts.Set("router-load-state", 300, () => {
            AppEvents.Emit("router-load-state-change", true);
        });
    });

    router.afterEach(() => {
        Timeouts.Abort("router-load-state");
        AppEvents.Emit("router-load-state-change", false);
    });

    return router;
}
