<template>
  <div class="modal-container modal-container-corner no-transition" :class="{ hidden: !display }" tabindex="-1"
    role="dialog" :aria-hidden="!display" @click="close" @keydown="escapeToClose">
    <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
      <div class="modal-header wrap">
        <div class="modal-title">
          <span>{{ $t("Wallet") }}</span>
        </div>
        <button type="button" class="modal-close-btn" :title="$t('Close')" @click="goBack">
          <i class="fas fa-times"></i>
        </button>
        <div class="wallet-address d-flex" v-if="address">
          <div class="col-3">
            {{ $t("Address") }}:
          </div>
          <div class="col-9" @click="copyWalletAddress">
            <p class=" link-deco me-2 cursor-pointer">{{ renderWalletAddress(address) }}</p>
            <i class="fa-regular fa-copy cursor-pointer copy-icon"></i>
            <i class="fa-solid fa-copy cursor-pointer copy-icon-hover"></i>
          </div>

        </div>
      </div>

      <div class="modal-body no-padding">
        <div class="settings-list">
          <div class="settings-list-item" tabindex="0" @keydown="clickOnEnter" @click="openPasswordChange">
            <div class="settings-list-item-icon">
              <i class="fas fa-key"></i>
            </div>
            <div class="settings-list-item-caption">
              {{ $t("Change password") }}
            </div>
          </div>

          <div class="settings-list-item" tabindex="0" @keydown="clickOnEnter" @click="openExport">
            <div class="settings-list-item-icon">
              <i class="fas fa-lock-open"></i>
            </div>
            <div class="settings-list-item-caption">
              {{ $t("Export private key") }}
            </div>
          </div>
        </div>
      </div>

      <div class="modal-body no-padding border-top">


        <div class="settings-list-item" tabindex="0" @keydown="clickOnEnter" @click="openWalletReplace">
          <div class="settings-list-item-icon">
            <i class="fas fa-trash-alt"></i>
          </div>
          <div class="settings-list-item-caption">
            {{ $t("Replace wallet") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AuthController } from "@/control/auth";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/v-model";
import { FocusTrap } from "@/utils/focus-trap";

export default defineComponent({
  name: "WalletSettingsDropdown",
  emits: ["update:display", "openModal"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      focusTrap: null as FocusTrap,
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      username: AuthController.Username,
      address: AuthController.Wallet ? AuthController.Wallet.address : "0x",
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    close: function () {
      this.displayStatus = false;
    },

    goBack: function () {
      this.close();
      this.$emit("openModal", "account-settings");
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    onAuthStatusUpdate: function () {
      this.username = AuthController.Username;
      this.address = AuthController.Wallet ? AuthController.Wallet.address : "0x";
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    openPasswordChange: function () {
      this.$emit("openModal", "wallet-password-change");
    },

    openExport: function () {
      this.$emit("openModal", "wallet-export");
    },

    openWalletReplace: function () {
      this.$emit("openModal", "wallet-replace");
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },

    copyWalletAddress: function () {
      navigator.clipboard.writeText(this.address);
      this.$showSnackBar(this.$t("Address copied to clipboard!"));
    },
  },
  mounted: function () {
    this.$listenOnAppEvent("auth-status-changed", this.onAuthStatusUpdate.bind(this));

    this.focusTrap = new FocusTrap(this.$el, this.close.bind(this), "top-bar-button-img");

    if (this.display) {
      this.onAuthStatusUpdate();
      this.focusTrap.activate();
      nextTick(() => {
        this.$el.focus();
      });
    }
  },
  beforeUnmount: function () {
    this.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.onAuthStatusUpdate();
        this.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.focusTrap.deactivate();
      }
    },
  },
});
</script>

<style scoped>
.copy-icon {
  color: var(--gsd-text-color);
}

.copy-icon-hover {
  color: var(--gsd-light-green);
  display: none;
}

.wallet-address:hover .copy-icon {
  display: none;
}

.wallet-address:hover .copy-icon-hover {
  display: inline;
}
</style>
