// Notifications controller

"use strict";

import { Timeouts } from "@/utils/timeout";
import { AppEvents } from "./app-events";
import { ApiNotifications } from "@/api/api-group-notifications";
import { Request } from "@asanrom/request-browser";
import { AuthController } from "./auth";

export class NotificationsController {
    public static UnreadCount = 0;
    public static TotalCount = 0;

    public static Initialize() {
        AppEvents.AddEventListener("auth-status-changed", NotificationsController.Load);
        NotificationsController.Load();
    }

    public static Load() {
        Timeouts.Abort("notifications-control-load");
        Request.Abort("notifications-control-load");

        if (!AuthController.isAuthenticated()) {
            return;
        }

        Request.Pending("notifications-control-load", ApiNotifications.GetStatus()).onSuccess(response => {
            NotificationsController.UnreadCount =  response.unread;
            NotificationsController.TotalCount =  response.total;
            AppEvents.Emit("notifications-status-changed", NotificationsController.UnreadCount, NotificationsController.TotalCount);
            // Check again after a minute
            Timeouts.Set("notifications-control-load", 60 * 1000, NotificationsController.Load);
        }).onRequestError(err => {
            Request.ErrorHandler()
                .add("*", "*", () => {
                    // Retry
                    Timeouts.Set("notifications-control-load", 10 * 1000, NotificationsController.Load);
                })
                .handle(err);
        }).onUnexpectedError(err => {
            console.error(err);
            // Retry
            Timeouts.Set("notifications-control-load", 10 * 1000, NotificationsController.Load);
        });
    }
}
