// API bindings: notifications (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { NotificationStatus, NotificationList, NotificationReadBody } from "./definitions";

export class ApiNotifications {
    /**
     * Method: GET
     * Path: /notifications
     * Get notification status
     * @returns The request parameters
     */
    public static GetStatus(): RequestParams<NotificationStatus, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/notifications`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /notifications
     * Deletes all notifications
     * @returns The request parameters
     */
    public static DeleteAllNotifications(): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/notifications`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /notifications/list
     * Lists notifications
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static ListNotifications(queryParams: ListNotificationsQueryParameters): RequestParams<NotificationList, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/notifications/list` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /notifications/read
     * Set notifications read
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ReadNotifications(body: NotificationReadBody): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/notifications/read`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /notifications/{id}/read
     * Set notification read
     * @param id Notification ID
     * @returns The request parameters
     */
    public static ReadNotification(id: string): RequestParams<void, ReadNotificationErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/notifications/${encodeURIComponent(id)}/read`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /notifications/{id}
     * Deletes notification
     * @param id Notification ID
     * @returns The request parameters
     */
    public static DeleteNotification(id: string): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/notifications/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Query parameters for ListNotifications
 */
export interface ListNotificationsQueryParameters {
    /**
     * Page. Starting by 1.
     */
    page?: number;
}

/**
 * Error handler for ReadNotification
 */
export type ReadNotificationErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

