<template>
    <div class="footer-layout">
        <footer class="footer">
            <div class="footer-container">
                <div class="footer-item border-right">
                    <div class="footer-item-title">GSD</div>

                    <ul class="footer-list">
                        <li>
                            <RouterLink :to="{ name: 'about' }">{{ $t("About Us") }}</RouterLink>
                        </li>
                        <li>
                            <a href="javascript:;">{{ $t("Join Us") }}</a>
                        </li>
                        <li>
                            <a href="javascript:;">{{ $t("Latest News") }}</a>
                        </li>
                    </ul>
                </div>

                <div class="footer-item border-right">
                    <div class="footer-item-title">{{ $t("Our Products") }}</div>

                    <ul class="footer-list">
                        <li>
                            <a href="javascript:;">{{ $t("NFTs market for companies and patents") }}</a>
                        </li>
                        <li>
                            <a href="javascript:;">{{ $t("Predictor of success of Biotech companies and patents") }}</a>
                        </li>
                        <li>
                            <a href="javascript:;">{{ $t("Design of innovative clinical developments based on AI") }}</a>
                        </li>
                    </ul>
                </div>

                <div class="footer-item border-right">
                    <div class="footer-item-title">{{ $t("Connect") }}</div>

                    <ul class="footer-list">
                        <li>
                            <a href="javascript:;" class="email">{{ $t("contract@pharmanet.com") }}</a>
                        </li>
                    </ul>
                </div>

                <div class="footer-item">
                    <div class="footer-item-title">{{ $t("Information") }}</div>

                    <div class="footer-item-description">{{ $t("Stay informed") }}</div>

                    <div class="form-outline">
                        <input type="email" class="form-control email" placeholder="Mail..."/>
                    </div>
                </div>
            </div>

            <div class="footer-background-icon">
                <img src="/svg/02-trama-fondo-grey.svg" alt="Background icon" />
            </div>
        </footer>

        <div class="footer-copyright">
            <div class="copyright-container">
                <span class="copyright-text">© 2024 Copyright: GSD</span>
            </div>
            <div class="privacy-terms-container">
                <p class="privacy-text">
                    <a href="/privacy" class="link-deco" target="_blank" rel="noopener noreferrer" :title="$t('Privacy Policy')">{{ $t("Privacy Policy") }}</a>
                </p>
                <p class="terms-text">
                    <a href="/terms" class="link-deco" target="_blank" rel="noopener noreferrer" :title="$t('Terms of use')">{{ $t("Terms of use") }}</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    name: "FooterComponent",
    data: function () {
        return {};
    },
    methods: {},
    mounted: function () {},
    beforeUnmount: function () {},
});
</script>

<style scoped>
@import "@/style/layout/footer.css";
</style>
