<template>
    <div class="modal-container modal-container-corner modal-notifications no-transition" :class="{ hidden: !display }"
        tabindex="-1" role="dialog" :aria-hidden="!display" @click="close" @keydown="escapeToClose">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-header-notifications d-flex align-items-center mt-3">
                <div class="col-7 d-flex justify-content-start ms-3">
                    <h5>{{ $t("Notifications") }}</h5>
                </div>
                <div class="col-4 d-flex justify-content-end mt-2">
                    <p v-if="notifications.length !== 0" class="clickable-text" @click="readAllNotifications()">{{
                        $t("Mark all read") }}</p>
                </div>
            </div>
            <div class="body-notifications">
                <p v-if="notifications.length === 0" class="d-flex justify-content-center mt-3 no-notification">{{
                    $t("No notifications available") }}</p>
                <div v-for="n in notifications" class="notification-item" :key="n.id">
                    <div class="d-flex align-items-center">
                        <div class="notification-logo">
                            <i class="fa-regular fa-bell icon-color"></i>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-if="n.data.type === 'new_kyc'" @click="goToVerificationRequestPage(n.data.requestId)">
                            <p class="mb-1">{{ $t("A new identity verification request is pending review.") }}</p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'kyc_accepted'">
                            <p class="mb-1">{{ $t("Your identity verification request was") }} <strong>{{ $t("accepted.") }}</strong></p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'kyc_rejected'">
                            <p class="mb-1">{{ $t("Your identity verification request was") }} <strong>{{ $t("rejected.") }}</strong></p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'patent_validated'" @click="goToPatent(n.data.patentId)">
                            <p class="mb-1">{{ $t("Your patent request has been validated") }}: <strong>{{ n.data.patentName }}</strong></p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'patent_cancelled'">
                            <p class="mb-1">{{ $t("Your patent request has been cancelled") }}: <strong>{{ n.data.patentName }}</strong></p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'project_validated'" @click="goToProject(n.data.projectId)">
                            <p class="mb-1">{{ $t("Your project request has been validated") }}: <strong>{{ n.data.projectTitle }}</strong></p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'project_cancelled'">
                            <p class="mb-1">{{ $t("Your project request has been cancelled") }}: <strong>{{ n.data.projectTitle }}</strong></p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'project_collaboration_request'" @click="goToCollaborationRequests">
                            <p class="mb-1">{{ $t("You have received a collaboration request for the project") }} <strong>{{ n.data.projectTitle }}</strong></p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'project_collaboration_accept_request'" @click="goToProject(n.data.projectId)">
                            <p class="mb-1">{{ $t("Your collaboration request for the project") }} <strong>{{ n.data.projectTitle }}</strong> {{ $t("has been accepted.") }}</p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'project_collaboration_reject_request'">
                            <p class="mb-1">{{ $t("Your collaboration request for the project") }} <strong>{{ n.data.projectTitle }}</strong> {{ $t("has been rejected.") }}</p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else-if="n.data.type === 'rate_expired'">
                            <p class="mb-1">{{ $t("Your $RATE rate subscription has expired.").replace('$RATE', formatPlan(n.data.rate)) }}</p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="flex-grow-1 ms-3 mt-3" v-else>
                            <p class="mb-1">{{ $t("Unknown notification.") }}</p>
                            <p class="notification-date">{{ getNotificationType(n.data.type) + " - " +
                                renderDate(n.date) }}</p>
                        </div>

                        <div class="ms-auto">
                            <a @click="readNotification(n.id)" :title="$t('Mark as Read')">
                                <i class="fa-solid fa-check icon-color notification-logo"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/v-model";
import { FocusTrap } from "@/utils/focus-trap";
import { renderPrettyDate } from "@/utils/time-utils";
import { getUniqueStringId } from "@/utils/unique-id";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";

import { AuthController } from "@/control/auth";
import { NotificationsController } from "@/control/notifications";
import { ApiNotifications } from "@/api/api-group-notifications";

export default defineComponent({
    name: "NotificationsDropdown",
    emits: ["update:display"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
            focusTrap: null as FocusTrap,
            displayStatus: useVModel(props, "display"),
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            loading: true,

            notifications: [],
            unread: NotificationsController.UnreadCount,
            busyRead: false,
            busyDelete: false,
        };
    },
    methods: {
        updateUnread: function () {
            this.unread = NotificationsController.UnreadCount;
        },

        open: function () {
            this.displayStatus = true;
        },

        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        clickOnEnter: function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                event.target.click();
            }
        },

        goToVerificationRequestPage: function (requestId: string) {
            this.$router.push({ name: "admin", query: { tab: "identity", requestId: requestId } });
            this.close();
        },

        goToPatent: function (patentId: string) {
            this.$router.push({ name: "patent-single", params: { id: patentId }});
            this.close();
        },

        goToProject: function (projectId: string) {
            this.$router.push({ name: "project-single", params: { id: projectId }});
            this.close();
        },

        goToCollaborationRequests: function () {
            this.$router.push({ name: "dashboard-projects", query: { showProjectCollaborations: "true" } });
            this.close();
        },

        load: function () {
            this.loading = true;

            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            if (!AuthController.isAuthenticated()) {
                return;
            }

            this.notifications = [];

            let oldest = Date.now();

            if (this.notifications.length > 0) {
                oldest = this.notifications[this.notifications.length - 1].date;
            }

            Request.Pending(this.loadRequestId, ApiNotifications.ListNotifications({}))
                .onSuccess((response) => {
                    this.loading = false;
                    for (const n of response.notifications) {
                        if (n.date < oldest) {
                            oldest = n.date;
                        }
                    }
                    for (const n of response.notifications) {
                        this.notifications.push(n);
                    }
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                });
        },

        readNotification: function (nid: string) {
            if (this.busyDelete) {
                return;
            }
            this.busyDelete = true;
            Request.Do(ApiNotifications.DeleteNotification(nid)).onSuccess(() => {
                this.busyDelete = false;
                this.load();
                NotificationsController.Load();
            }).onRequestError(err => {
                console.error(err);
            }).onUnexpectedError(err => {
                this.busyDelete = false;
                console.error(err);
            });
        },

        readAllNotifications: function () {
            if (this.busyDelete) {
                return;
            }
            this.busyDelete = true;
            Request.Do(ApiNotifications.DeleteAllNotifications()).onSuccess(() => {
                this.busyDelete = false;
                this.load();
                NotificationsController.Load();
            }).onRequestError(err => {
                console.error(err);
            }).onUnexpectedError(err => {
                this.busyDelete = false;
                console.error(err);
            });
        },

        showProfile: function (uid: string) {
            this.$showProfileModal(uid);
        },

        renderDate: function (ts: number) {
            return renderPrettyDate(ts, this.$t);
        },

        getNotificationType: function (type: string) {
            if (type === "new_kyc" || type === "kyc_accepted" || type === "kyc_rejected") {
                return this.$t("Verification");
            } else if (type === "patent_validated" || type === "patent_cancelled") {
                return this.$t("Patent");
            } else if (type === "project_validated" || type === "project_cancelled") {
                return this.$t("Project");
            } else if (type === "project_collaboration_request" || type === "project_collaboration_accept_request" || type === "project_collaboration_reject_request") {
                return this.$t("Collaboration");
            } else if (type === "rate_expired") {
                return this.$t("Rate");
            } else {
                return this.$t("Unknown");
            }
        },

        formatPlan: function (plan: string) {
            return plan === "SILVER" ? this.$t("Silver") : this.$t("Gold");
        },
    },
    mounted: function () {
        this.load();

        this.focusTrap = new FocusTrap(this.$el, this.close.bind(this), "top-bar-button");

        if (this.display) {
            this.focusTrap.activate();
            nextTick(() => {
                this.$el.focus();
            });
        }
    },
    beforeUnmount: function () {
        this.focusTrap.destroy();

        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);
    },
    watch: {
        display: function () {
            if (this.display) {
                this.focusTrap.activate();
                nextTick(() => {
                    this.$el.focus();
                });
            } else {
                this.focusTrap.deactivate();
            }
        },
    },
});
</script>

<style scoped>
@import "@/style/notifications-modal.css";
</style>
